<template>
  <!-- 导航 -->
  <div class="ss">
    <Top />
    <iframe :src="IM.url" class="iframe_im"></iframe>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import Top from '@/components/Top/top.vue';
const route = useRoute();
const IM = reactive({});
import { fetchIMAuth } from '@/api/fetcher';
import { baseUrl } from '@/api/index';
const LoginIm = async () => {
  const result = await fetchIMAuth();
  let IM = {
    data: {},
    url: '',
  };
  if (result) {
    const IMData = JSON.parse(result);
    IM.data = IMData;
    IM.url = `${baseUrl()}?ThirdLogin&tgid=${IMData.UserID}&token=${
      IMData.Token
    }&theme=dark&lang=${localStorage.getItem('lang') || 'en'}`;
    localStorage.setItem('IM', JSON.stringify(IM));
  }
};

onMounted(() => {
  let s = localStorage.getItem('IM');
  if (s) {
    Object.assign(IM, JSON.parse(s));
  } else {
    LoginIm();
  }

  IM.url = IM.url + `&domain=${route.query?.domain}`;
});
</script>

<style lang="less" scoped>
.ss {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.iframe_im {
  width: 100%;
  flex: 1;
}
</style>
